import Lottie from "lottie-react";
import devAnimationData from "../../assets/lotties/page-not-found.json";
const NotFound = () => {
  return (
    <div className="flex-row items-center justify-center text-center">
      <Lottie animationData={devAnimationData} />
    </div>
  );
};

export default NotFound;
