import React from "react";
import { useFormik } from "formik";
import { AiOutlineMail } from "react-icons/ai";
import axios from "axios";
import * as yup from "yup";

const Formik = () => {
  const inputFS =
    "border-[2px] border-[#0E1F58] hover:border-[2px] hover:border-gray-400";

  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

  const schema = yup.object({
    firstName: yup
      .string()
      .trim()
      .matches(/^[A-Za-z]+$/, "First name can have alphabets only")
      .required("First Name is required"),
    lastName: yup
      .string()
      .trim()
      .matches(/^[A-Za-z]+$/, "Last name can have alphabets only")
      .required("Last Name is required"),
    email: yup.string().email().required("Email is required"),
    message: yup.string().min(10),
    contactNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number is required"),
  });

  const { values, handleSubmit, handleChange, errors, resetForm } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      contactNumber: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: schema,
    onSubmit: () => {
      handleFormSubmit();
      resetForm();
    },
  });

  const { firstName, lastName, email, message, contactNumber } = values;

  const handleFormSubmit = async () => {
    try {
      const res = await axios.post(
        `https://dev-api.mybeatroute.com/cmn/send-from-mail`,
        {
          email: email,
          from_email: "marketing@blinkfix.in",
          subject: "Thank you for contacting us",
          body: `<!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0" />
              <meta http-equiv="X-UA-Compatible" content="ie=edge" />
              <title>blinkFix</title>
              <link rel="stylesheet" href="style.css" />
            </head>
            <body
              style="
                background-color: rgb(240, 248, 255);
                margin: auto;
                align-items: center;
                font-family: helvetica;
              "
            >
              <div style="height: 20px; background-color: #2e9ad7; margin: 0 50px"></div>
              <div class="" style="margin: auto; text-align: center">
                <img
                  width="200px"
                  height="70px"
                  src="https://zopperwarranty-pub.zopper.com:86/homeassure/media/LjSkIPmZWpUF6Yijn2EY1A=="
                  alt=""
                  style="margin-top: 20px; align-items: center"
                />
              </div>
              <h1 style="text-align: center; margin-top: 80px; font-weight: 150">Thank you for contacting Blinkfix</h1>
              <h2 style="color: #116798; text-align: center; margin-bottom: 80px; font-weight: 150">Hello ${firstName} ${lastName}</h2>
              <b>
              <p
              style="
                text-align: center;
                font-size: larger;
                margin-top: 20px;
                font-family: helvetica;
                font-weight: 600;
              "
            >
              Your Following details have been successfully stored with us.
            </p>
            <p
              style="
                text-align: center;
                font-size: larger;
                margin-top: 10px;
                position: relative; bottom: 20px;
                margin-bottom: 40px;
                font-family: helvetica;
                font-weight: 600;
              "
            >
              Our team will connect with you shortly on the shared details.
            </p>
              </b>
              <div
                class=""
                style="
                  width: 100%;
                  margin: auto;
                  text-align: center;
                  font-family: helvetica;
                "
              >
                <table
                  style="
                    border: 1px solid rgb(128, 189, 243);
                    border-collapse: collapse;
                    margin: 0 auto;
                    width: 450px;
                    height: 250px;
                    font-weight: 250;
                  "
                >
                  <tr>
                    <th
                      style="
                        border: 1px solid rgb(128, 189, 243);
                        border-collapse: collapse;
                        font-weight: 350;
                      "
                    >
                      First Name
                    </th>
                    <td
                      style="
                        border: 1px solid rgb(128, 189, 243);
                        border-collapse: collapse;
                        font-weight: 350;
                      "
                    >
                      ${firstName}
                    </td>
                  </tr>
                  <tr style="background-color: rgb(128, 189, 243)">
                    <th
                      style="
                        border: 1px solid rgb(128, 189, 243);
                        border-collapse: collapse;
                        font-weight: 350;
                      "
                    >
                      Last Name
                    </th>
                    <td
                      style="
                        border: 1px solid rgb(128, 189, 243);
                        border-collapse: collapse;
                        font-weight: 350;
                      "
                    >
                    ${lastName}
                    </td>
                  </tr>
                  <tr>
                    <th
                      style="
                        border: 1px solid rgb(128, 189, 243);
                        border-collapse: collapse;
                        font-weight: 350;
                      "
                    >
                      Email ID
                    </th>
                    <td
                      style="
                        border: 1px solid rgb(128, 189, 243);
                        border-collapse: collapse;
                      "
                    >
                    ${email}
                    </td>
                  </tr>
                  <tr style="background-color: rgb(128, 189, 243)">
                    <th
                      style="
                        border: 1px solid rgb(128, 189, 243);
                        border-collapse: collapse;
                        font-weight: 350;
                      "
                    >
                      Contact Number
                    </th>
                    <td
                      style="
                        border: 1px solid rgb(128, 189, 243);
                        border-collapse: collapse;
                      "
                    >
                    ${contactNumber}
                    </td>
                  </tr>
                  <tr>
                    <th
                      style="
                        border: 1px solid rgb(128, 189, 243);
                        border-collapse: collapse;
                        font-weight: 350;
                      "
                    >
                      Message
                    </th>
                    <td
                      style="
                        border: 1px solid rgb(128, 189, 243);
                        border-collapse: collapse;
                      "
                    >
                    ${message}
                    </td>
                  </tr>
                </table>
          
                <img
                  width="400px"
                  height="300px"
                  src="https://zopperwarranty-pub.zopper.com:86/homeassure/media/LVAegLQ3SYUxYa8v1YPNGQ=="
                  alt=""
                  style="margin-top: 20px; align-items: center"
                />
              </div>
          
              <script src="index.js"></script>
            </body>
          </html>
          `,
        }
      );
      alert(
        "Message sent successfully, you will hear very soon from BlinkFix!"
      );
      console.log("data---------", res.data);
    } catch (error) {
      console.log("error--------", error);
    }
  };
  // const handleFormSubmit = async () => {
  //   try {
  //     const res = await axios.post(
  //       `https://dev-api.mybeatroute.com/cmn/send-mail`,
  //       {
  //         email: email,
  //         body: `<div>${firstName}</div>`,
  //         subject: ` Thank you for contacting us`,
  //       }
  //     );
  //     alert("Message sent successfully, you will hear very soon from zopper!");
  //     console.log("data---------", res.data);
  //   } catch (error) {
  //     console.log("error--------", error);
  //   }
  // };

  return (
    <div id="Form">
      <form className="max-w-[100vw]" onSubmit={handleSubmit}>
        <div className="form-container text-[#0E1F58] border-w flex flex-wrap">
          <div className="wrapper-info m-auto md:max-w-[50vw] lg:max-w-[50vw] max-w-[80%] md:min-w-[40%] lg:min-w-[40%]">
            <div className="flex-col items-center justify-center info-form p-[7%] text-[#0E1F58] md:max-w-[100%] lg:max-w-[100%]">
              <h1 className="text-center text-5xl font-normal py-4 text-[#0E1F58]">
                Contact
              </h1>
              <p className="py-4 font-extralight">
                <div className="flex-col items-center text-center justify-items-center">
                  <AiOutlineMail className="inline mr-4 text-3xl m-2" />
                  <a href="mailto:email@gmail.com">marketing@blinkfix.in</a>
                </div>
              </p>
            </div>
          </div>
          <div className="p-[40px] flex flex-wrap gap-4 md:w-[40%] lg:w-[40%]">
            <div className="flex flex-col relative" style={{ flex: "45%" }}>
              <label className="text-xs md:text-sm lg:text-sm">
                First Name
              </label>
              <input
                className={`${inputFS}`}
                name="firstName"
                type="text"
                onChange={handleChange}
                value={firstName.trimStart()}
              />
              <p className="text-red-600 absolute bottom-[-16px] text-xs right-0">
                {errors.firstName}
              </p>
            </div>

            <div className="flex flex-col relative" style={{ flex: "45%" }}>
              <label className="text-xs md:text-sm lg:text-sm">Last name</label>
              <input
                className={`${inputFS}`}
                name="lastName"
                type="text"
                onChange={handleChange}
                value={lastName.trimStart()}
              />
              {
                <p className="text-red-600 absolute bottom-[-16px] text-xs right-0">
                  {errors.lastName}
                </p>
              }
            </div>

            <div className="flex flex-col relative" style={{ flex: "100%" }}>
              <label className="text-xs md:text-sm lg:text-sm">Email</label>
              <div className="">
                <input
                  className={`${inputFS} w-[100%]`}
                  name="email"
                  type="text"
                  onChange={handleChange}
                  value={email.trimStart()}
                />
                {
                  <p className="text-red-600 absolute bottom-[-16px] text-xs right-0">
                    {errors.email}
                  </p>
                }
              </div>
            </div>

            <div className="flex flex-col relative" style={{ flex: "100%" }}>
              <label className="text-xs md:text-sm lg:text-sm">
                Contact Number
              </label>
              <div className="">
                <input
                  className={`${inputFS} w-[100%]`}
                  name="contactNumber"
                  type="text"
                  onChange={handleChange}
                  value={contactNumber.trimStart()}
                />
                {
                  <p className="text-red-600 absolute bottom-[-16px] text-xs right-0">
                    {errors.contactNumber}
                  </p>
                }
              </div>
            </div>

            <div className="flex flex-col relative" style={{ flex: "100%" }}>
              <label className="text-xs md:text-sm lg:text-sm">Message</label>
              <textarea
                className={`${inputFS}`}
                name="message"
                rows="5"
                cols="40"
                onChange={handleChange}
                value={message.trimStart()}
              />
              <p className="text-red-600 absolute bottom-[-16px] text-xs right-0">
                {errors.message}
              </p>
            </div>

            <div className="md:ml-auto sm:ml-auto ml-auto">
              <button
                type="submit"
                className="text-white bg-[#01425F] hover:text-[#01425F] hover:border-[1px] hover:border-[#01425F] hover:bg-white w-[14vw] md:w-[10vw] sm:w-[10vw] py-2 px-4 font-poppins text-[8px] lg:text-sm md:text-sm flex-end text-center transition duration-700"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Formik;
