import React from "react";
const Clients = ({ name, image, url }) => {
  return (
    <div className="">
      <div className="md:w-[200px] md:h-[200px] w-[140px] h-[140px]">
        <a href={url} target="_blank">
          <img
            className="rounded-full drop-shadow-2xl md:w-[200px] md:h-[200px] w-[140px] h-[140px] bg-white object-contain"
            src={image}
            alt={name}
          />
        </a>
      </div>
      <div className="bg-[#01425F] flex flex-row items-center justify-center py-4 px-6 relative bottom-6 md:w-[200px] lg:w-[200px] w-[150px]">
        <p className="text-xs font-bold text-white">{name}</p>
      </div>
    </div>
  );
};

export default Clients;
