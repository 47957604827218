import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footer mb-20 pt-20 text-center md:text-sm text-xs ">
        <p className="font-light pb-4">marketing@blinkfix.in</p>
        <p className="font-light">
          ©2022 Blinkfix private limited | All rights reserved
        </p>
      </div>
    </>
  );
};

export default Footer;
