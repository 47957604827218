import React, { useState, useRef } from "react";
import { Link } from "react-scroll";
import { Route } from "react-router-dom";
import Logo from "../img/BlinkFixLogo.webp";

// const gotoContent = () =>
//     window.scrollTo({
//       top: ServicesRef.current.offsetTop,
//       behavior: "smooth",
//       // You can also assign value "auto"
//       // to the behavior parameter.
//     });

const Header = () => {
  const [darkToggle, setDarkToggle] = useState(false);
  return (
    <>
      <div
        className={`flex flex-row justify-between font-poppins p-[10px] lg:bg-[#01425F] md:bg-[#01425F] bg-[#01425F] w-full dark:bg-gray-900 ${
          darkToggle && "dark"
        }`}
      >
        {/* <label class="toggleDarkBtn">
            <input type="checkbox" onClick={() => setDarkToggle(!darkToggle)} />
            <span class="slideBtnTg round">Toggle dark theme</span>
          </label> */}
        <div className="flex flex-row sm:p-1 items-center dark:bg-gray-900">
          <img
            className="object-scale-down w-[120px] lg:ml-[35px] ml-[1px]"
            src={Logo}
            alt=""
          />
        </div>
        <div className="flex gap-4 navbar lg:p-[20px] text-white dark:bg-gray-900">
          {/* <Link className="text-xs md:text-sm " to="/insurance">
              Home
            </Link> */}

          {/* <button onClick={gotoContent}>Content</button> */}

          <Link
            className="text-xs md:text-sm cursor-pointer"
            activeClass="active"
            to=""
            spy={true}
            smooth={true}
          >
            Home
          </Link>
          <Link
            className="text-xs md:text-sm cursor-pointer "
            activeClass="active"
            to="Content"
            spy={true}
            smooth={true}
          >
            Insurer
          </Link>
          <Link
            className="text-xs md:text-sm cursor-pointer "
            activeClass="active"
            to="Form"
            spy={true}
            smooth={true}
          >
            Contact us
          </Link>
        </div>
        {/* <div className="jl"ref={ServicesRef}>lshbvjkbv</div> */}
      </div>
      {/* <Routes>
          <Route exact path="/shop" element={<ProductsPage />} />
        </Routes> */}
    </>
  );
};

export default Header;
