import AppRouter from "./routes/Routes";

function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
