import { React, useState, useRef } from "react";
import kotak from "../../img/Kotak_Mahindra_Bank_logo (1).png";
import digit from "../../img/digit-insurance-logo.png";
import zuno from "../../assets/image.png";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import insurancePolicyImg from "../../img/Group 174207.png";
import Clients from "../../components/Clients";
import { Button, Modal } from "antd";
import carouselImg1 from "../../img/HealthInsurance-3Dec-01.jpg";
import carouselImg2 from "../../img/4W 1400 cashless garages.jpg";
import carouselImg3 from "../../img/CashlessHospitals-01.jpg";
import carouselImg4 from "../../img/garage-01.jpg";
import carouselImg5 from "../../img/GI of the year- Third Party-01.jpg";
import carouselImg6 from "../../img/Health Insurance Banners_31Mar-04.jpg";
import carouselImg7 from "../../img/HealthInsurance-3Dec-01.jpg";
import carouselImg8 from "../../img/POLICY-01.jpg";
import carouselImg9 from "../../img/Pricing-Third Party-01.jpg";
import carouselImg10 from "../../img/SC02837_Digit_Performance Marketing_Car Banners_6_Months_1200x628px.jpg";
import carouselImg12 from "../../img/SC02837_Digit_Performance Marketing_Car Banners_Great_Deals_1200x628px.jpg";
import carouselImg13 from "../../img/zuno_banner.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./home.css";
import { AiOutlineCaretRight as RightIcon } from "react-icons/ai";
import { AiOutlineCaretLeft as LeftIcon } from "react-icons/ai";

const clientList = [
  // {
  //   id: 1,
  //   name: "Kotak general insurance",
  //   image: kotak,
  //   url: "https://www.kotakgeneral.com/",
  // },
  {
    id: 2,
    name: "Digit insurance",
    image: digit,
    url: "https://www.godigit.com/",
  },
  {
    id: 3,
    name: "Zuno",
    image: zuno,
    url: "https://www.hizuno.com/",
  },
];

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      {/* <div className="mt-35"></div> */}

      <div className="w-full h-[40%] lg:h-full md:h-full sm:object-cover ">
        <div className="h-full self-center w-[80vw] lg:w-[28vw] md:w-[48vw] flex flex-col  rounded drop-shadow-xl text-[#0e1f58] p-4 lg:p-10 max-h-[500px] lg:absolute lg:top-[25%] lg:left-[10%] md:absolute md:top-[15%] md:left-[5%] absolute top-[19%] left-[10%] gap-6 min-w-[150px] object-contain text-left">
          <h1 className="lg:text-6xl md:text-2xl text-4xl font-medium font-poppins text-left">
            Insurance
          </h1>

          <p className="font-poppins text-[15px] font-extralight lg:mt-4 text-left lg:p-[5px] w-[100%]">
            Insurance is a contract (policy) in which an insurer indemnifies
            another against losses from specific contingencies or perils. There
            are many types of insurance policies. Life, health, homeowners, and
            auto are the most common forms of insurance. The core components
            that make up most insurance policies are the deductible, policy
            limit, and premium.
          </p>
          <button
            className=" border-[1px] bg-black text-white border-[#0e1f58] text-md lg:text-sm self-start py-[4px] px-[8px] lg:py-2 lg:px-4 drop-shadow-xl hover:text-[#000000] hover:border-[#000000] hover:bg-white object-contain"
            onClick={showModal}
          >
            Learn more
          </button>
          <Modal
            title="Insurance"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
          >
            <p>
              {" "}
              <text>
                <p>
                  <b>What is an insurance? </b>
                </p>
                <text className="text-justify">
                  An insurance is a legal agreement between an insurer
                  (insurance company) and an insured (individual), in which an
                  insured receives financial protection from an insurer for the
                  losses he may suffer under specific circumstances. Under an
                  insurance policy, the insured needs to pay regular amount of
                  premiums to the insurer. The insurer pays a predetermined sum
                  assured to the insured if an unfortunate event occurs, such as
                  death of the life insured, or damage to the insured or his
                  property.
                </text>
                <p className="py-4">
                  <b>Types of Insurance:</b>
                </p>
                <p className="pt-4">
                  <b> Health insurance</b>
                </p>
                <text className="text-justify">
                  Health insurance plans cover your emergency medical expenses.
                  Health insurance plans either reimburse or pay the treatment
                  cost for any illness or injury.
                </text>
                <p className="pt-4">
                  <b>Motor insurance</b>
                </p>
                <text className="text-justify">
                  Motor insurance offers your financial assistance in case your
                  vehicle is involved in an accident. It is mandatory to have
                  motor insurance in India for your bike, car, and commercial
                  vehicle.
                </text>
                <p className="pt-4">
                  <b>Term insurance</b>
                </p>
                <text className="text-justify">
                  Term insurance is the purest and most affordable plan among
                  all life insurance plans. Term life insurance only offers the
                  risk cover. Thus, the premium cost is significantly low for a
                  large sum assured. With a nominal premium amount, you get
                  adequate life cover for your family. If anything happens to
                  you, your family can still sustain their life and meet life
                  goals.
                </text>
              </text>
            </p>
          </Modal>
        </div>

        {/* <img
              className="w-[40vw] border-4 border-black ml-[40%] mt-[5%]"
              src={insurancePolicyImg}
              alt=""
            /> */}

        <div className="bg-[url('img/bLINKFIX1.png')] h-[80vh] bg-no-repeat bg-cover "></div>

        <div className="p-4 mt-[10vh] text-center">
          <h1 className="lg:text-5xl md:text-3xl text-xl font-semibold font-poppins mt-10 mb-6 text-[#0E1F58]">
            Welcome to Blink Fix
          </h1>
          <div>
            <p className="font-poppins font-extralight text-l p-[5%] lg:py-[35px] text-center lg:px-[250px]">
              Blinkfix helps in marketing, brand promotion in both online and
              offline mode for the insurance companies. We also help in creating
              and developing digital marketing strategies and campaigns that
              include various digital strategy, branding etc.
            </p>
          </div>
        </div>
      </div>

      <div id="Content" className="bg-[#bbc8f5] p-12 ">
        <div className="images flex justify-evenly flex-wrap">
          {clientList.map((client) => (
            <Clients
              key={client.id}
              name={client.name}
              image={client.image}
              url={client.url}
            />
          ))}
        </div>
      </div>
      {/* <img src={carouselImg}alt="" className="border-b-[1px] border-[eff0f3] mb-10"/> */}
      <Carousel
        className="cursor-pointer"
        emulateTouch
        autoPlay
        showArrows={true}
        onClickItem={() => window.open("https://www.godigit.com/", "_blank")}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${
                hasPrev ? "absolute" : "hidden"
              } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <LeftIcon className="w-20 h-20 text-black" />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${
                hasNext ? "absolute" : "hidden"
              } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <RightIcon className="w-20 h-20 text-black" />
            </div>
          );
        }}
      >
        <div>
          <a href="https://www.godigit.com/" target="_blank">
            <img src={carouselImg1} />
            {/* <p className="legend">Legend 1</p> */}
          </a>
        </div>
        <div>
          <img src={carouselImg2} />
          {/* <p className="legend">Legend 2</p> */}
        </div>
        <div>
          <img src={carouselImg3} />
          {/* <p className="legend">Legend 3</p> */}
        </div>
        <div>
          <img src={carouselImg4} />
        </div>
        <div>
          <img src={carouselImg5} />
        </div>
        <div>
          <img src={carouselImg6} />
        </div>
        <div>
          <img src={carouselImg7} />
        </div>
        <div>
          <img src={carouselImg8} />
        </div>
        <div>
          <img src={carouselImg9} />
        </div>
        <div>
          <img src={carouselImg10} />
        </div>
        <div>
          <img src={carouselImg12} />
        </div>
      </Carousel>
      <Carousel
        className="cursor-pointer"
        emulateTouch
        autoPlay
        showArrows={true}
        onClickItem={() => window.open("https://www.hizuno.com/", "_blank")}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            <div
              className={`${
                hasPrev ? "absolute" : "hidden"
              } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <LeftIcon className="w-20 h-20 text-black" />
            </div>
          );
        }}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            <div
              className={`${
                hasNext ? "absolute" : "hidden"
              } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
              onClick={clickHandler}
            >
              <RightIcon className="w-20 h-20 text-black" />
            </div>
          );
        }}
      >
        <div>
          <a href="https://www.hizuno.com/" target="_blank">
            <div className="m-10">
              <img src={carouselImg13} />
            </div>

            {/* <p className="legend">Legend 1</p> */}
          </a>
        </div>
      </Carousel>
    </div>
  );
};

export default Home;
