import React from "react";
import { Route, Routes } from "react-router";
import Home from "../pages/Home/Home";
import Header from "../components/Header";
import Formik from "../components/Formik";
import Footer from "../components/Footer";
import NotFound from "../pages/not-found/NotFound";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="/"
        element={
          <>
            <Header />
            <Home />
            <Formik />
            <Footer />
          </>
        }
      />
    </Routes>
  );
};

export default AppRouter;
